import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/user", {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchUser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/user/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/auth/register", userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    },
};
